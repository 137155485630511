@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/antd/style';
@import 'assets/styles/bootstrap/style';
@import 'assets/styles/airui/style';
@import 'assets/styles/nprogress/style';
@import 'assets/styles/chartist/style';
@import 'assets/styles/chartist-tooltip-plugin/style';
@import 'assets/styles/jvectormap/style';
@import 'assets/styles/react-sortable-tree/style';
@import '~c3/c3.min.css';
@import 'assets/styles/custom.css';
