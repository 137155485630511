.ant-pagination-extra {
  margin-top: 40px
}

.ant-pagination-extra li {
  margin-right: 4px;
}

.ant-pagination {
  display: inline-block;
  position: relative;
  top: 11px;
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.pagination-area {
  margin-top: 25px;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  overflow-x: hidden;
}

.ant-row.ant-form-item {
  margin-bottom: 0px
}

.ant-input {
  padding: 4px 13px !important
}

.ant-modal-footer.actions {
  margin: 0 -24px;
  padding-bottom: 0;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
}

.actions .ant-btn {
  display: inline-block;
  position: relative;
  width: auto;
}

.actions i.anticon.anticon-loading {
  display: inline-block;
  position: absolute;
  left: 8px;
  bottom: 6px;
}

.zip-code-item {
  display: flex;
  justify-content: space-between
}

.zip-query {
  font-weight: 600;
  min-width: 40px;
  margin-right: 10px;
}

.ant-input-affix-wrapper {
  padding: 0 8px !important;
}

.anticon.anticon-search.ant-input-search-icon {
  margin-top: 8px;
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 4px 4px !important;
}

.ant-form-item-explain {
  font-size: 14px !important;
}

.ant-picker {
  width: 100%;
}

/*
  .style_subbar__3yvBv {
    background: #f2f4f8!important;
  } 
*/

.colStart {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.page-title {
  padding-left: 15px;
  margin-right: 50px !important;
  text-transform: initial;
  position: relative;
  top: 3.4px;
}

.ant-modal-title,
.ant-form-item-label>label {
  user-select: none;
  -webkit-user-select: none;
}

.ant-modal-footer.actions {
  margin-top: 15px;
}

.style_footer__1o1NL.style_footerFullWidth__Moa5Y {
  user-select: none;
  -webkit-user-select: none;
}

.topbarDarkLogo {
  position: relative;
  width: 260px;
}

.topbarDarkLogo div {
  position: absolute;
  right: -21px;
  width: 142px;
}

.topbarDarkLogo img {
  margin-top: 0.1rem !important;
}

body .ant-dropdown-menu {
  border: 0 !important;
}

@media (max-width: 768px) {
  .displayNoneResponsive {
    display: none !important;
  }

  .topbarDarkLogo {
    width: auto;
  }
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 5px !important;
}

.ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content>.ant-tabs-tabpane {
  border: 1px solid #f0f0f0;
  padding: 16px;
  margin-top: -16px;
  border-top: 0;
  margin-bottom: 16px;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: initial;
}

.ant-input[disabled] {
  background: initial;
}

@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }

  .air__utils__toolbox .ant-form-item-control-input {
    margin-bottom: 5px;
  }

}

.workOrder .cardBody {
  padding: 20px;
}

.ant-input-number.total {
  font-weight: bold;
  font-size: 25px;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  pointer-events: none !important;
  position: relative;
  top: -4px;
  width: 100% !important;
}

.totalCard {
  padding: 10px 20px 5px 20px !important;
}

.amountPriceTotal {
  display: flex;
  flex-direction: row;
}

.infoLabel {
  color: #161537;
  font-size: 15px;
  padding-bottom: 10px;
}

.infoValue {
  color: #161537;
  font-size: 15px;
}

.text-dark.font-size-18.font-weight-bold.mb-1 {
  color: #786fa4 !important;
  font-size: 17px !important;
}

.infoValue.addressLines {
  margin-left: -10px;
}

.ant-input-number {
  width: 100% !important
}

.formVertical .ant-form-item-label>label {
  margin-bottom: 0;
}

.formVertical .ant-form-item {
  margin-bottom: .5rem;
}

.cardHeader {
  padding: 0 20px;
  padding-top: 20px;
  margin-bottom: -5px;
}

.subbar {
  background: #f3f7ff !important
}

.infoCostTypes {
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  float: right;
}

.infoCostTypes span {
  background: #eee;
  padding: 3px 13px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #e4e9f0;
  display: inline-block;
}

span.colStart {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 45px;
}

span.colEnd {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deviceLocation {
  padding-left: 10px;
}

body .ant-dropdown-menu-item>a,
body .ant-dropdown-menu-submenu-title>a {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.greenBar * {
  color: green;
}

.redBar * {
  color: red;
}

.amountCode {
  border-radius: 12.5px;
  width: 25px;
  height: 25px;
  color: #000 !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 16px;
  margin-top: 3px;
  margin-left: 10px;
}

.greenBar .amountCode {
  background-color: green !important;
  border: 1px solid #fff;
}

.redBar .amountCode {
  background-color: red !important;
  border: 1px solid #fff;
}

.redBar .amountGraph i {
  background-color: red !important;
}

.amountCode span {
  color: #fff !important;
  font-weight: 500;
  position: relative;
  top: 0px;
  line-height: 0;
  font-size: 14px;
}

.displayNoneResponsive {
  position: relative;
}

.amountGraph {
  background: #4977e991;
  border-radius: 5px;
}

.amountText {
  font-size: 13px !important;
  position: relative;
  top: -2px;
}

.amountValue {
  font-size: 13px !important;
  color: #484848;
}

.contractInfoLabel,
.projectNumber {
  font-size: 1.05rem !important;
}

.contractNumber {
  font-size: 1.05rem !important;
  font-weight: 600;
}

@media(max-width: 1200px) {
  .new-button {
    min-height: 30px !important;
  }

  .btn-with-addon.new-button {
    padding-left: 0 !important;
  }

  .new-button {
    height: 34px !important;
    min-width: 39px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow: hidden !important;
  }

  .new-button .new-label {
    display: none !important;
  }
}

.cardBody.totalCard {
  pointer-events: none !important;
  -webkit-pointer-events: none !important;
}

.reportForm {
  margin: 30px;
  margin-bottom: 0;
  max-width: 478px;
}

.reportForm .cardBody {
  padding: 16px;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-right .btn {
  margin-left: 15px;
}

.budgetInfos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.budgetinfo {
  display: flex;
  margin-right: 20px;
}

.reportCards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.card.reportForm {
  min-width: 400px;
}

.excelImage {
  width: 50px;
  margin-bottom: 34px;
}

.btn-green {
  background-color: #107a40 !important;
}

.btn.btn-primary.btn-green:hover:active,
.btn.btn-primary.btn-green:focus,
.btn.btn-primary.btn-green.active,
.show>.btn.btn-primary.btn-green:hover:active,
.show>.btn.btn-primary.btn-green:focus,
.show>.btn.btn-primary.btn-green.active {
  background-color: #107a40 !important;
}

@media(max-width:1438px) {
  .breadcrumbLink {
    display: none !important
  }
}

.ant-table-tbody>tr.ant-table-row:active>td,
.ant-table-tbody>tr.ant-table-row:focus>td {
  background: #1b55e3 !important;
}

.toolbox {
  padding: 30px;
}

.toolbox .reportForm {
  max-width: 100% !important;
  width: 100% !important;
  margin: 0;
  min-width: auto !important;
}

.sectionSubTitle {
  text-align: left;
  color: #786fa4 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding-bottom: 15px;
}

.pagination-area.toptable {
  margin-top: -40px;
  padding-bottom: 15px;
}

.buttonsSuzam div {
  margin-left: 10px;
  float: right;
}

.btn-black {
  background-color: #161537 !important
}

.btn.btn-primary.btn-black:hover:active,
.btn.btn-primary.btn-black:focus,
.btn.btn-primary.btn-black.active,
.show>.btn.btn-primary.btn-black:hover:active,
.show>.btn.btn-primary.btn-black:focus,
.show>.btn.btn-primary.btn-black.active {
  background-color: #161537 !important;
}

.btn-yellow {
  background-color: chocolate !important
}

.btn.btn-primary.btn-yellow:hover:active,
.btn.btn-primary.btn-yellow:focus,
.btn.btn-primary.btn-yellow.active,
.show>.btn.btn-primary.btn-yellow:hover:active,
.show>.btn.btn-primary.btn-yellow:focus,
.show>.btn.btn-primary.btn-yellow.active {
  background-color: chocolate !important;
}

.budget-row {
  margin-bottom: 5px;
}

.definitioncode {
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  padding-top: 1rem;
}

.ant-table-wrapper.hidepagination .ant-head-tbody>tr.ant-table-row:hover>td,
.ant-table-wrapper.hidepagination .ant-head-tbody>tr.ant-table-row:active>td,
.ant-table-wrapper.hidepagination .ant-head-tbody>tr.ant-table-row:focus>td {
  background: transparent !important;
}

.ant-dropdown-menu-submenu {
  min-width: 150px;
}

.DisabledField {
  pointer-events: none !important;
}

.DisabledField .ant-picker {
  background: #e0e0e0;
}

@media(max-width:1400px) {
  .ant-table-content {
    overflow: scroll hidden;
  }
}

.deviceLocation {
  margin-left: 34%;
  margin-bottom: 15px;
  line-height: 17px;
}

.infoValue.addressLines {
  color: #676767;
}

.hide {
  display: none !important;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  width: 210px !important;
}

.select-with-plus {
  position: relative;
}

.col-xl-5 .select-with-plus .ant-row.ant-form-item .ant-form-item-control {
  flex: 0.85 1;
}

.col-xl-7 .select-with-plus .ant-row.ant-form-item .ant-form-item-control {
  flex: 0.9 1;
}

.select-with-plus .plus-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: #4977e9;
  max-height: 32px;
}

.select-with-plus .plus-btn .btn-addon {
  top: 0 !important;
  left: -1px !important;
}

.select-with-plus .plus-btn .btn-addon i {
  color: #fff !important;
}

.otherServices thead.ant-table-thead {
  display: none !important;
}

.otherServices .ant-table-content {
  margin: - 0;
}

.otherServices td.ant-table-cell {
  padding: 4px 12px !important;
}

.otherServices .ant-spin-container {
  margin-bottom: 10px;
}

.otherServices .ant-form-item-label {
  visibility: hidden;
  opacity: 0;
}

.superGrids .ant-table-column-sorter-full {
  display: none !important;
}

.superGrids .ant-table-filter-trigger-container {
  display: none !important;
}

.superGrids th.ant-table-cell {
  max-width: 60px !important;
}

.ant-empty-normal {
  margin: 0 !important;
  margin-top: 10px !important;
}

.mt10 {
  margin-top: 10px;
}

.btn-group button {
  margin-right: 10px;
  min-width: 110px;
  text-align: left;
  border-radius: 4px !important;
}

.topbarDarkLogo div {
  margin-top: 5px !important;
}

img.invoiceImage {
  max-width: 60px;
  margin-bottom: 20px;
}

.customCol {
  padding-left: 5px;
  padding-right: 15px;
}

.customCol:nth-child(1) {
  padding-right: 5px;
}

.cardBody.totalCard.reperationLimit {
  padding: 10px 20px !important;
}

.cardBody.totalCard {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.cardBody.totalCard.totalAmount {
  padding-bottom: 10px !important;
}

.cardBody.totalCard.totalAmount input {
  font-weight: bold !important;
}

.card.costsToHigh .totalAmount input {
  color: red !important;
  font-weight: bold !important;
  background: #eee !important;
  width: 100% !important;
}

.card.generalInformations,
.card.serviceInformations {
  min-height: 445px !important;
}




.mb-4.air__utils__scrollTable.workOrder.completed .card .recallBtn,
.mb-4.air__utils__scrollTable.workOrder.completed .card .saveButton,
.mb-4.air__utils__scrollTable.workOrder.completed .card .printButton:not(.dont),
.mb-4.air__utils__scrollTable.workOrder.completed .card div:not(.dont)>.ant-form-item {
  position: relative;
  pointer-events: none;
  cursor: not-allowed !important;
  user-select: none !important;
  -webkit-user-selec: none !important;
}

.mb-4.air__utils__scrollTable.workOrder.completed .card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #a2a2a21a;
}

.dont .ant-form-item {
  z-index: 2;
  position: relative;
}

.mb-4.air__utils__scrollTable.workOrder.completed .card::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url(/resources/images/locked.svg) !important;
  background-position: center center !important;
  background-size: 20px 20px;
  background-repeat: no-repeat !important;
  z-index: 2;
  top: 10px;
  right: 15px;
  display: none;
}

img.lockedSvg {
  max-height: 100%;
  height: auto;
  width: 30px;
  margin-right: 15px;
  margin-left: 15px;
}

.locked {
  font-size: 18px;
}

.locked b {
  margin-right: 15px;
}

.locked .alert {
  padding: 15px;
}

.mb-4.air__utils__scrollTable.workOrder.completed .newButton {
  display: none !important;
}

.alertbox i {
  font-size: 21px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.alertbox span {
  position: relative;
  top: -1px;
}

span.text-align-right {
  min-width: 100px !important;
  float: left;
}

.col-xl-9 .card {
  min-height: 242px;
}

.ant-table-tbody tr:last-child>td {
  border-bottom: 0 !important;
}

.parts .ant-table-tbody tr td:nth-child(2) {
  max-width: 159px;
  text-overflow: ellipsis;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
}

.pulseAnimate .amountCode {
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse-animation 2s infinite;
  background: red !important;
}

.addressName {
  margin-bottom: 3px;
}

.row.fixPads .ant-form-item-control-input {
  padding-left: 5px;
}

.zip-code-item.alignLeftOnly1 .colStart {
  min-width: 10px !important;
  width: 40px !important;
  flex: none !important;
}

.zip-code-item.alignLeftOnly1 .colEnd {
  text-align: left !important;
  flex: none !important;
  float: left !important;
}

.zip-code-item.alignLeftOnly1 {
  justify-content: flex-start;
}

.otherServices.alignLeftOnly1 {
  overflow: hidden;
}

.full-history-button {
  margin-top: 25px;
  text-align: right;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  padding-right: 5px;
}

.otherServices td.ant-table-cell:nth-child(1) {
  padding-right: 7px !important;
}

.priceInfo {
  border: 0 !important;
  font-size: 23px !important;
  line-height: 23px !important;
  color: #786fa4 !important;
}

td.ant-table-cell {
  max-width: 300px;
  text-overflow: ellipsis;
}

.autocomplete-dropdown {
  position: relative;
}

.autocomplete-dropdown-items {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 15px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.15);
  top: 0;
  right: 0;
  width: 66.6667%;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}

.autocomplete-dropdown-item {
  padding: 4px 12px
}

.autocomplete-dropdown-item:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #ebf4ff;
}

.spaceBottom {
  margin-bottom: 15px;
}

.expiredate .ant-form-item-control-input input {
  color: red !important;
}

span.note {
  float: right;
  font-size: 16px;
  font-weight: normal;
}

span.completed__text {
  float: right;
  color: #fb434a;
  font-size: 14px;
}

.completed__status input,
.completed__status .ant-input-group-addon {
  border-color: #fb434a;
}

.completed__status .ant-input-group-addon i {
  color: #fb434a;
}

.completed__status input,
.not__updatable input {
  background: #f2f4f8 !important;
  pointer-events: none !important;
  user-select: none !important;
}

.gridHeightFixed {
  max-height: 300px;
  overflow: auto;
  position: relative;
}

.card.cardPadding {
  padding: 20px 0 !important;
}

.col-md-6.quan .ant-form-item-control {
  padding-left: 40px;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical li {
  width: 210px !important;
  max-width: 210px !important;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

img.emilICon {
  height: 46px;
  margin-bottom: 20px;
}

.filterparam {
  float: left;
  width: 200px;
  max-width: 200px;
  margin-right: 15px;
}


.filterparam.bigger {
  float: left;
  width: 400px;
  max-width: 400px;
  margin-right: 15px;
}

.air__utils__toolbox {
  float: left;
  background: #f3f7ff;
  padding: 10px;
  width: calc(100% - 60px);
  margin: 10px 30px;
  z-index: 5;
  position: relative;
}

.ant-tooltip.ant-tooltip-placement-top {
  display: none !important;
}

.ant-input::placeholder,
.ant-picker-input>input::placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
}

.filter__trigger_hide .ant-table-filter-trigger-container {
  display: none !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 8px 8px !important;
}

.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0 !important;
}

.ant-table-column-sorters {
  padding: 8px 8px !important;
}

th.ant-table-cell {
  overflow: hidden !important;
}

.card.woButtons {
  padding-bottom: 0 !important;
}

.card.woButtons .cardBody {
  padding-bottom: 10px;
}

.card.woButtons .btn-group {
  display: block;
}

.woButtons .btn-group .btn {
  display: inline-block !important;
  float: left;
  min-width: 122px !important;
  margin-bottom: 10px;
}

/* .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding-right: 10px !important;
} */

.filterparam.small {
  float: left;
  width: 115px;
}

a.btn-with-addon.text-nowrap.d-md-block.back-button {
  width: 60px !important;
  height: 33.25px !important;
  margin-left: -15px !important;
}

a.btn-with-addon.text-nowrap.d-md-block.back-button i {
  color: #999999;
  font-size: 2rem;
}

.filterparam.medium {
  float: left;
  width: 140px !important;
}

.woButtons .btn-group .btn {
  min-width: 188px !important;
}

@media(max-width: 1367px) {
  .filterparam {
    display: inline-block;
    float: none;
    min-width: 250px;
    margin-bottom: 5px !important;
  }

  .clearFilters {
    float: left;
    display: inline;
  }

  .air__utils__toolbox {
    margin-left: 15px;
    width: calc(100% - 30px);
  }
}

.woButtons2 {
  display: none;
}

@media(max-width: 1150px) {
  .woButtons {
    display: none;
  }

  .woButtons2 {
    display: flex;
    min-height: auto !important;
  }
}


@media(max-width:1300px) and (min-width: 1200px) {
  .col-md-6.customCol.orderNumber {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px !important;
    margin-bottom: 5px;
  }

  .col-md-6.customCol.dont.switchOrderNumber {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 8px;
  }

  .col-xl-5 .select-with-plus .ant-row.ant-form-item .ant-form-item-control {
    flex: 0.77 1 !important;
  }

  .col-xl-7 .select-with-plus .ant-row.ant-form-item .ant-form-item-control {
    flex: 0.85 1 !important;
  }
}

.ant-modal.modal-locked {
  position: relative;

}

.ant-modal.modal-locked .ant-form-item-control {
  pointer-events: none;
  cursor: not-allowed !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  -webkit-user-selec: none !important;
}

.ant-modal.modal-locked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #a2a2a21a;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  min-height: auto;
  display: block;
  height: auto;
}

.dont .not .ant-form-item-control {
  position: relative;
  pointer-events: none;
  cursor: not-allowed !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  -webkit-user-selec: none !important;
  z-index: 0;
  z-index: 1;
}

.dont .not .ant-select-selector {
  background: #f6f6f6;
}

.ant-row.ant-form-item.disabled .ant-input-number {
  background: #eee !important;
  cursor: default;
  pointer-events: none;
  -webkit-pointer-events: none;
}

.mb-4.air__utils__scrollTable.filter__trigger_hide.small_size_columns span.ant-table-filter-column-title {
  padding-right: 20px;
}

.clearFilters button {
  float: left;
  margin-right: 15px;
}

.nopadd .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 !important;
}


.customerSignature img {
  border: 1px solid #e4e9f0;
  border-radius: 4px;
}

img.photoThumb {
  max-width: 150px;
}

.oneline tbody.ant-table-tbody {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.green_color {
  color: green !important
}


.dirty input,
.dirty .ant-input-group-addon {
  border-color: green !important
}

.dirty .ant-input-group-addon i {
  color: green !important
}


button.btn.btn-success.btn-with-addon.text-nowrap.d-md-block.btn-print.recallBtn.recall {
  background-color: #878787;
  border-color: #878787;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.downloadButton {
  width: 100%;
  margin-top: 10px;
  text-align: left;
}

.air__part_list .filterparam {
  width: auto !important;
  margin: 0px 2px !important;
}

.show_scroll .ant-table-content {
  overflow: auto;
}

.ant-btn.editDays.ant-btn-success {
  background-color: #46be8a;
  border-color: #46be8a;
  color: #fff;
}

.active {
  color: #000
}

.deactive {
  color: red
}

.air__part_list .filterparam {
  width: 170px !important;
}

.air__utils__toolbox.air__part_list .clearFilters {
  margin-left: 20px;
  padding-left: 20px;
  float: none;
}

.air__utils__toolbox.air__part_list .clearFilters .new-button {
  margin-left: 5px;
  height: 31px;
  width: 167px;
  margin-right: 1px !important;
}

.air__utils__toolbox.air__part_list .clearFilters .new-button .new-label {
  position: relative;
  top: -1px;
}


.partOrderArriveds .text-dark {
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: -25px;
}

.partOrderArriveds_grid {
  padding: 20px
}

.ant-row.ant-form-item.disabled input {
  pointer-events: none;
  background: #efefef;
}

.ant-row.ant-form-item.filterparam.longer {
  width: 300px !important;
  max-width: 300px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  padding-right: 40px;
}

.toolbox3 {
  margin-bottom: 30px;
}

.projectNumber {
  max-width: 350px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.new-button {
  min-width: 125px;
}

.btn.btn-warning.btn-with-addon.text-nowrap.d-md-block.btn-print.printButton.maintenance {
  background-color: #d76e97;
  border-color: #d76e97;
}

.ant-input.disabled {
  background: #eee;
  pointer-events: none !important;
  -webkit-pointer-events: none !important;
}

.card.costViews .totalCard:nth-child(1) {
  margin-top: 20px;
}

.card.costViews .totalCard {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 5px !important;
}

.card.costViews .totalCard.totalAmount {
  padding-bottom: 20px !important;
}

.partOrderArriveds.s2 .card.generalInformations {
  min-height: auto !important;
}

.partOrderArriveds.s2 {
  margin-top: 15px !important;
}

.fixspace .ant-col.ant-form-item-label.ant-form-item-label-left.ant-col-xs-24.ant-col-sm-4 {
  flex: 0 0 16.1%;
  max-width: 16.1%;
}

.fixspace .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-20 {
  max-width: 83.9%;
}

.ant-btn.printPartTransfer.ant-btn-warning {
  background: #ffb000;
  border-color: #ffb000;
  color: #fff;
}

.ant-btn.editDays.e2.ant-btn-success {
  background: #9c52d5;
  border-color: #9c52d5;
  color: #fff;
}

span.downloadText {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: block;
}

.withautocomplete {
  position: relative;
  left: 0;
  float: left;
}

.withautocomplete .autocomplete-dropdown {
  position: absolute;
  top: 36px;
  left: -135px;
  width: 415px;
}

.withautocomplete .ant-form-item {
  width: 262px !important;
  max-width: 202px !important;
}

.ant-row.ant-form-item.disabled .ant-form-item-control-input-content,
.ant-row.ant-form-item.disabled .ant-form-item-control-input-content .ant-picker,
.ant-row.ant-form-item.disabled .ant-form-item-control-input-content .ant-select-selector {
  pointer-events: none;
  -webkit-pointer-events: none;
  background: #efefef;
}

.generalInformationTab label {
  font-weight: normal;
}

.card.generalInformations.autoHeight {
  min-height: auto !important;
}

.card.proposalTotal {
  padding-top: 20px;
}

.proposal img.photoThumb {
  max-width: 75px;
}

.proposal .ant-table-filter-trigger-container {
  display: none !important;
  position: inherit !important;
  visibility: hidden !important;
  width: 0 !important;
  height: 0 !important;
}

.fadeout {
  opacity: 0.5;
  pointer-events: none !important;
}

.checkb span {
  position: relative;
  margin-left: 5px;
}

.checkb {
  margin: 0;
}

button.btn.btn-warning.btn-with-addon.text-nowrap.d-md-block.btn-print.printButton.dont.createWorkOrder {
  background: #161636;
  border-color: #161636;
}

.proposalDetailRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proposalDetailRow button.btn.btn-success.btn-with-addon.text-nowrap.d-md-block.btn-print.recallBtn.proposalGo {
  margin: 20px 0;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

.proposalDetailRow span {
  font-weight: 600;
  font-size: 17px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 12px;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  color: rgba(0, 0, 0, 0.65);
}


.ant-notification-notice-message {
  font-size: 14px !important
}


.yellowBar .amountCode {
  background: #fcff4f !important;
  color: #000 !important;
  border: 1px solid #aaa;
}

.yellowBar .amountCode span {
  color: #000 !important;
}


.icct_tech {
  margin-top: -20px;
}

.icct_tech .ant-col.ant-form-item-label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.icct_tech .ant-row.ant-form-item {
  margin-bottom: 15px;
}


.customerSignature {
  max-width: 498px;
  width: 100%;
}

img.photo {
  max-width: 100%;
}


.fixSpace label {
  margin-bottom: 0;
}

.ant-row.ant-form-item.fixSpace {
  margin-bottom: 5px;
}

.minHeight200 {
  min-height: 200px !important
}

.card.serviceInformations.minHeight200 {
  min-height: 200px !important;
}

.card.technicians.x2 {
  min-height: auto !important;
}

.workOrder .priceInfo {
  margin-top: -5px;
}

.select-with-plus.serviceOrderPDF {
  margin-top: 8px;
}

.select-with-plus.serviceOrderPDF .ant-input {

  font-size: 13px;
  padding: 3px 10px !important;
}

.serviceOrderPDF .plus-btn {
  position: absolute;
  z-index: 2;
}

.select-with-plus.serviceOrderPDF button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.downloadButton {
  margin-top: 0;
  margin-bottom: 10px;
  height: 32px;
  padding-top: 5px;
}

.icc .ant-form-item-control-input {
  padding-left: 0 !important;
}

.onhand .ant-form-item-control-input {
  padding-left: 0 !important;
}

.dont .not.update .ant-select-selector {
  position: relative;
  pointer-events: none;
  cursor: not-allowed !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  -webkit-user-selec: none !important;
  z-index: 0;
  z-index: 1;
  cursor: default !important;
}

.assetHistory .ant-modal-body {
  height: 600px !important;
  overflow-y: scroll;
}

.card.customerSignature img {
  border: 0;
}


button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.btn-save.saveButton.moveToWorkOrder {
  background: #c073a9;
  border-color: #c073a9;
}

.cardBody.x11 .ant-table-tbody>tr>td:first-child {
  max-width: 180px;
}

.col-xl-7 .select-with-plus.assetmax .ant-row.ant-form-item .ant-form-item-control {
  flex: 0.76 1;
}

button.btn.btn-primary.text-nowrap.plus-btn.editAsset {
  right: 54px;
}

.inventoryID .plus-btn {
  position: absolute;
  z-index: 2;
}

.select-with-plus.dont.inventoryID.assetmax.a2 .ant-form-item-control {
  flex: 0.70 1;
}

.select-with-plus.dont.inventoryID.assetmax.a2 .editAsset {
  right: 51px;
}

.price_list .child label {
  padding-left: 15px;
}

.price_list .cardBody.totalCard.reperationLimit.costView2:not(.child) {
  margin-bottom: 0 !important;
  border-top: 1px solid #e7e7e7ee;
  padding-top: 10px !important;
}

.price_list .cardBody.totalCard.totalAmount {
  border-top: 1px solid #eee;
}

.price_list .cardBody.totalCard.reperationLimit.costView2.child {
  margin-bottom: -5px !important;
}

.price_list .child input.ant-input.priceInfo {
  font-size: 18px !important;
  font-weight: 500;
}

.price_list2 {
  display: flex;
  flex-direction: column;
}

.price_list2 .costView2.child.child2:first-child {
  /* border-top: 1px solid #cfcfcf; */
  flex: 1;
  padding-top: 10px;
}

.costView2.child.child2 {
  margin-bottom: -8px;
}

.costView2.child.child2 .ant-form-item-control-input input {
  font-size: 16px !important;
}

.costView2.child.child2 .ant-form-item-label {
  padding-left: 35px;
}

.cardBody.totalCard.laborTotal.to2 {
  margin-top: 0;
  border-top: 1px solid #dddd;
  padding-top: 10px !important;
}

.cardBody.totalCard.reperationLimit.costView2.c23 {
  border-top: 1px solid #dddd;
  padding-top: 10px !important;
}

.price_list2 {
  padding-bottom: 10px;
}


.cardBody.totalCard.reperationLimit.costView2.c23:first-child {
  border-top: 0;
  padding-top: 0 !important;
}

.price_list2 .costView2.child.child2:first-child {
  padding-top: 0 !important;
}

.price_list2 {
  padding-bottom: 20px;
}


button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.new-button.import23 {
  margin-left: 10px;
  margin-right: 0px !important;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.new-button.import23.i6 {
  background-color: #c3bedc;
  border-color: #c3bedc;
}

button.btn.btn-primary.btn-with-addon.text-nowrap.d-md-block.new-button.import23.i7 {
  background-color: #c3bedc;
  border-color: #c3bedc;
}


.select-crud .ant-form-item-control {
  flex: 0.78 1;
}

.select-crud .plus-btn {
  position: absolute;
  z-index: 2;
}

.select-crud .editAsset {
  right: 48px !important;
}

.select-crud .addAsset {
  right: 0;
}


.assethistory .ant-table-thead>tr>th {
  padding: 5px !important;
}

.assethistory .ant-table-tbody>tr>td {
  padding: 5px !important;
}

.descriptionLong .ant-col-xl-20 {
  padding-left: 0;
  margin-left: -5px;
}

.clindBudget .amountCode {
  width: 70px;
  border-radius: 4px;
  background-color: #b6b5d3 !important;
  height: 33px !important;
  margin-top: 0;
}

.emailSend {
  flex: 1;
  width: 100%;
  margin-top: -15px;
  padding-bottom: 0 !important;
}

label.sendEmailDescription {
  word-wrap: break-word;
  width: 100%;
  white-space: break-spaces;
  flex: 1;
}

.nicedk .ant-form-item-control {
  padding-left: 6px !important;
}

.select-with-plus.select-crud.supplierID .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-16 {
  flex: 0.65;
}


.price_list .cardBody.totalCard.reperationLimit.costView2:not(.child):first-child {
  border: 0;
}

.cardBody.totalCard.totalAmount {
  border-top: 1px solid #e7e7e7ee;
  margin-top: 15px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}


.ant-row.ant-form-item.filterparam.sdah {
  width: 300px !important;
  max-width: 300px !important;
}

.part-price-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}







.priceinfobox {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

span.left23 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

span.total23 {
  text-align: right;
}

span.qty23 {
  text-align: right;
}

.priceinfobox {
  background: #fbfbfb;
  padding: 5px 15px;
}

.priceinfobox.header {
  background: #e8f0fe;
}

.part-price-info {
  margin-top: 5px;
}

span.percent23 {
  text-align: center !important;
}

.part-price-info {
  display: flex;
}

.priceinfobox.header {
  display: flex;
  width: 100%;
}

span.left23 span {
  width: 135px;
}

.select-crud.s239 .ant-form-item-control {
  flex: 0.57 1;
}

.part-price-info {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid #f5f5f5;
}

.select-crud.s239.widht3 .ant-form-item-control {
  flex: 0.825 1;
}





.pricing_list {
  display: flex;
  flex-direction: column;
}

.price_item.parent {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}

.price_item {
  padding: 0 15px;
}

.price_item {
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price_value.value2 {
  text-align: right;
  font-size: 17px !important;
  font-weight: bold;
  color: #f9a100 !important;
}

.price_item div {
  width: 33%;
  color: #161537;
  font-size: 15px;
}

.price_value {
  text-align: right;
  font-size: 17px !important;
  font-weight: bold;
  color: #786fa4 !important;
}

.price_label {
  font-weight: 500;
  color: #786fa4 !important;
}

.price_item.child {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
  padding-right: 35px;
}

.price_item div {
  font-size: 15px;
}

.price_item.parent.red_color .price_value:not(.value2) {
  color: red !important;
}

.price_item.parent.red_color .price_value2 {
  color: #f9a100 !important;
}

.price_item.parent.red_color .price_value2 {
  color: #f9a100 !important;
}

.price_item.parent.red_color {
  background: #fff7f7;
}

.pr_title,
.price_value.value2.pr_title {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #786fa4 !important;
}

.pricing_list .price_item.parent:first-child {
  border: 0;
  padding: 20px 30px;
  background: #fcfcfc;
}

.price_item.parent {
  padding-top: 10px;
  padding-bottom: 10px;
}

.price_item.parent.total_all>* {
  font-size: 18px !important;
}

.price_item.parent.total_all {
  padding-top: 20px;
}

.addNewLineForm .ant-form-item-label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.descriptionLong .ant-col-xl-20 {
  max-width: 100%;
}

span.qty23 {
  width: 100px;
}

span.price23 {
  width: 250px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fullwi .ant-form-item-control-input {
  margin-left: -5px;
}

button.btn.btn-danger.btn-with-addon.text-nowrap.d-md-block.btn-print.printButton.dont.printInvoice {
  background: #7FAC47;
}

.fib .ant-form-item-label {
  margin: 0;
  padding: 0;
}

.fib .ant-form-item-control {
  flex: 1;
  max-width: 100% !important;
  width: 100% !important;
}


.fib2 .col-lg-4:first-child {
  padding-right: 0;
}

.fib2 .col-lg-4:last-child {
  padding-right: 15px;
  padding-left: 0;
}

.fib2 .col-lg-4:nth-child(2) {
  padding: 0 10px;
}

.fib .ant-form label {
  padding-bottom: 0 !important;
}

.fib .ant-form-item-label>label {
  height: 20px;
}

.fib .ant-row.ant-form-item {
  margin-bottom: 5px;
}

button.ant-btn.printNew {
  background: #ff7a00;
  border-color: #ff7a00;
}


.make_disable .ant-form-item-control-input {
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}

.make_disable .ant-picker {
  background: #ebebeb !important;
}

.half_disable .ant-input {
  background: #ddd !important;
  height: 30px;
}

button.btn.btn-danger.btn-with-addon.text-nowrap.d-md-block.btn-print.printButton.dont.printInvoice {
  background: #818cb7;
  border-color: #818cb7;
}

.row.tax_included .col-lg-6 {
  padding: 0;
}

.row.tax_included {
  padding-left: 15px;
}

.row.tax_included .col-lg-6:nth-child(2) {
  padding-left: 15px;
  padding-top: 25px;
}

.half_disable textarea#workOrderNumbers {
  pointer-events: none !important;
  user-select: none !important;
}

button.ant-btn.newLine {
  background: #2f9b15;
  border-color: #2f9b15;
}

button.ant-btn.printNew2.ant-btn-primary {
  background: #c817ff;
  border-color: #c817ff;
}

.card.woButtons .cardBody button {
  max-width: 190px !important;
}

div#taxIncluded {
  margin-top: -15px;
}

button.btn.btn-primary.service_order_print {
  margin-left: 5px;
  background-color: #ff7e15;
  border-color: #ff7e15;
}

.materialGrid .ant-table-tbody>tr>td {
  max-width: 80px !important;
}

.materialGrid .ant-table-tbody>tr>td:nth-child(1) {
  max-width: 270px !important;
}

.emailDelivery_category_name {
  font-size: 15px !important;
}

.row.emailDelivery_category {
  margin-bottom: 15px;
}

.emailDelivery_category_name2 {
  font-weight: 600;

}

.row.emailDelivery_category .col-md-4 {
  color: #161537;
}

.emailArrays {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-left: 0;
  border-bottom: 1px solid #e0e0e0;
}

.emailArrays:last-child {
  border: 0;
}

span.email_close {
  cursor: pointer;
}

.newEmailFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
}

.da312 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 365px !important;
}

.da312 .ant-row.ant-form-item {
  max-width: 100% !important;
  width: 100% !important;
  margin-right: 20px !important;
}

.da314 button {
  background: #ff9b00;
  color: #fff;
  border: 0;
}

.emailDelivery_category_name.emailDelivery_category_name2 {
  background: #f1f1f1;
  padding: 5px 10px;
  border: 1px solid #ddd;
}

span.email_name {
  font-size: 14px;
  color: #1b55e3;
  padding-left: 10px;
}

.emailDelivery_ {
  background: #8180910a;
  border: 1px solid #ddd;
  border-top: 0;
}

.emailArrays {
  padding-right: 10px;
}

.select-with-plus.callerName_requester .ant-form-item-control {
  flex: 0.65 1 !important;
  
}
